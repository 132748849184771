import React, { useState } from "react";
import { useMutation } from "react-query";
import { Alert, Col, Row, Table, FormGroup, Input } from "reactstrap";
import { Receipt } from "react-bootstrap-icons";
import IconButton from "../../../components/Button";
import { sendTransactionReceipt, getCustomerDetails } from "../../../query/queryFunctions"; // Import getCustomerDetails here
import { useAuthContext } from "../../../context/authContext";
import { toast } from "react-toastify";
import TableRow from "./Row";
 
const TransactionHistory = ({
  customer,
  charges,
  toggleRefundModal,
  setRefundData,
  setRefunds,
  toggleRefundDetailsModal,
}) => {
  const { user } = useAuthContext();
  const [searchText, setSearchText] = useState("");
  const [selectedTransactions, setSelectedTransactions] = useState([]);
 
  const handleToggleRefundModal = (charge) => {
    toggleRefundModal();
    const data = {
      chargeId: charge.id,
      amount:
        Math.round(
          (charge.amount / 100 -
            charge.amount_refunded / 100 +
            Number.EPSILON) *
            100
        ) / 100,
      actualAmount: charge.amount / 100 - charge.amount_refunded / 100,
      reason: "",
    };
    setRefundData(data);
  };
 
  const handleToggleRefundDetailsModal = (refunds) => {
    toggleRefundDetailsModal();
    setRefunds(refunds);
  };
 
  const getFilteredTransactions = () => {
    let filteredTransactions = [];
    filteredTransactions =
      charges &&
      charges?.filter(
        (charge) =>
          charge.id.slice(20).includes(searchText) ||
          charge.payment_intent.includes(searchText)
      );
    return filteredTransactions.length > 0
      ? filteredTransactions
      : charges?.data;
  };
 
  const mutation = useMutation(sendTransactionReceipt, {
    onSuccess: () => {
      setSelectedTransactions([]);
    },
  });
 
  const { mutate, isLoading } = mutation;
 
  const handleRecieptEmailModal = async (charge) => {
    if (!charge || !charge.id) {
      console.error("Invalid charge object provided");
      return;
    }
 
    console.log("Full charge object:", JSON.stringify(charge, null, 2));
 
    const transactionsDetails = {
      id: charge.id,
      amount: (charge.amount / 100).toFixed(2),
      created: new Date(charge.created * 1000).toLocaleDateString(),
      receipt_url: charge.receipt_url,
      description: charge.description,
    };
 
    // Prioritize email sources
    let receiverEmail =
      charge.receipt_email || charge.billing_details?.email;
 
    // If no email found in charge object, fetch customer details
    if (!receiverEmail && charge.customer) {
      console.log(
        "No email found in charge object. Fetching customer details..."
      );
      try {
        const customerDetails = await fetchCustomerDetails(charge.customer);
        receiverEmail = customerDetails.email;
        console.log("Fetched customer email:", receiverEmail);
      } catch (error) {
        console.error("Error fetching customer details:", error);
        toast.error("Failed to fetch customer email. Please try again.");
        return;
      }
    }
 
    if (!receiverEmail) {
      console.error("No email address found for charge:", charge.id);
      toast.error(
        "No email address found for this transaction. Unable to send receipt."
      );
      return;
    }
 
    console.log("Final receiver email:", receiverEmail);
 
    const payload = {
      brand: user?.data.brand,
      brandLogo: user?.data.logo,
      receiver: receiverEmail,
      transactions: [transactionsDetails],
    };
 
    try {
      await mutate(payload);
      toast.success("Email will be sent shortly!");
    } catch (error) {
      console.error("Error sending receipt email:", error);
      toast.error("Failed to send receipt email. Please try again.");
    }
  };
 
  // Updated fetchCustomerDetails function using getCustomerDetails
  const fetchCustomerDetails = async (customerId) => {
    console.log(customerId, "customerid");
    try {
      const response = await getCustomerDetails(customerId); // Use the getCustomerDetails function from queryFunctions
      console.log("Customer details response:", response); // Log the response for debugging
 
      // Check if the response contains customer details and the email
      if (!response || !response.customer || !response.customer.email) {
        throw new Error("Customer not found or email not available");
      }
 
      return response.customer; // Return the customer object
    } catch (error) {
      console.error("Error fetching customer details:", error);
      throw error;
    }
  };
 
 
  const handleSendTransactionReceipt = () => {
    const payload = {
      brand: user?.data.brand,
      brandLogo: user?.data.logo,
      receiver: customer,
      transactions: selectedTransactions,
    };
    mutate(payload);
  };
 
  return (
    <Row>
      <Col>
        <FormGroup className="mb-3">
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search a transaction"
          />
        </FormGroup>
        {selectedTransactions?.length > 0 && (
          <FormGroup className="float-right">
            <IconButton
              title="Send Receipt"
              className="btn-sm"
              color="primary"
              disabled={isLoading}
              icon={<Receipt className="mr-2" />}
              onClick={handleSendTransactionReceipt}
            />
          </FormGroup>
        )}
        <Table responsive bordered hover>
          <thead>
            <tr className="text-center">
              <th></th>
              <th>Card</th>
              <th>Transaction ID</th>
              <th>Invoice #</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Paid Date</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {charges?.length > 0 ? (
              getFilteredTransactions() &&
              getFilteredTransactions().map((charge, index) => (
                <TableRow
                  key={index}
                  charge={charge}
                  isLoading={isLoading}
                  handleToggleRefundModal={handleToggleRefundModal}
                  handleToggleRefundDetailsModal={
                    handleToggleRefundDetailsModal
                  }
                  handleRecieptEmailModal={handleRecieptEmailModal}
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                />
              ))
            ) : (
              <tr>
                <td colSpan={9}>
                  <Alert color="info">
                    This customer has no transaction yet.
                  </Alert>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};
 
export default TransactionHistory;
